
const config = {
  "account": {
    "contact": "garrett@cognetix.ai",
    "id": "cognetix",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/cognetix/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/cognetix/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/cognetix/assets/img/brand/logo.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/cognetix/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://cognetix.ai",
      "name": "Cognetix AI, Inc."
    }
  },
  "theme": {
    "palette": {
      "color-1": "#F0F3F7",
      "color-2": "#18153D",
      "color-3": "#EF646D",
      "color-4": "#9178F5",
      "color-5": "#F0F3F7"
    }
  }
}

export default config;
