/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Button,
  Label,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// Firestore
import { firestore } from "./../../../../firebase";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_CUSTOMER,
  SET_ACTIVE_CUSTOMER_ACTIVITY,
  SET_PERSONA,
  SET_QUERY
} from 'store/actions';

function ViewCustomer({ profile, account, knowledge, insights, report, model, dispatch }) {
  // Get context
  const { id } = useParams();
  console.log("Insights: ", insights);

  // Get history
  const history = useHistory();

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }
  }, [ account ]);

  // Load customer
  useEffect(() => {
    // [1] Loading custoemr
    if (account.initialized && !insights.selectedCustomer && id) {
      // Load customer data
      firestore.collection("customers").doc(id).get()
        .then((doc) => {
          if(doc.exists) {
            // Update customer
            const customerData = doc.data();

            // Update local store
            dispatch({
              type: SET_ACTIVE_CUSTOMER,
              payload: customerData
            });
          }
        })
    }

    // [2] Lookup customer data
    if (account.initialized && insights.selectedCustomer && !insights.selectedCustomer.data.activity) {
      console.log(" --> Looking up customer details...");

      // Load customer reference data
      firestore.collection(insights.selectedCustomer.source.ref.collection)
        .doc(insights.selectedCustomer.source.ref.id).get()
        .then((doc) => {
          if(doc.exists) {
            // Update customer
            const customerReference = doc.data();

            console.log(" - Customer Reference: ", customerReference);

            // Lookup customer user identity
            const customerIdentityId = customerReference.data.customer_id;

            // Load customer user reference
            firestore.collection("customer_identities_v0.9")
              .where("account", "==", account.config.accountId)
              .where("client", "==", account.config.defaultClient)
              .where("identity.key", "==", "shopify_customer_id")
              .where("identity.value", "==", customerIdentityId)
              .orderBy("updated", "desc").limit(30)
              .onSnapshot((querySnapshot) => {
                // Iterate and append to list
                let userIds = [];
                let userIdentity;
                querySnapshot.forEach((doc) => {
                  // Get data
                  userIdentity = doc.data();

                  // Parse user ID
                  userIds.push(userIdentity.source.ref.id);
                });

                // Load user data from Cortex
                console.log(" -- User IDs: ", userIds);

                // Fetch and load results
                if(userIds.length > 0) {
                  // Setup event API
                  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/events/?distinct_id=${userIds[0]}&limit=10`;
                  console.log(eventsApiUrl);

                  // Fetch results
                  const request = new XMLHttpRequest();
                  request.open("GET", eventsApiUrl, false);
                  request.setRequestHeader('Authorization', 'Bearer ' + account.config.events.api);
                  request.send(null);

                  if (request.status === 200) {
                    // Parse event log
                    const eventLogsRaw = JSON.parse(request.responseText);

                    // Iterate over results
                    let eventHistory = [];
                    for(var i = 0; i < eventLogsRaw.results.length; i++) {
                      eventHistory.push({
                        event: eventLogsRaw.results[i].event,
                        datetime: eventLogsRaw.results[i].timestamp
                      });
                    }

                    // Update local store
                    dispatch({
                      type: SET_ACTIVE_CUSTOMER_ACTIVITY,
                      payload: eventHistory
                    });
                  }
                }
              }, (error) => {
                console.log(error);
              });
          }
        })
    }
  }, [ insights ]);

  return (
    <>
      <KnowledgeHeader
        headline="Customer Profile"
        description=""
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card className="bg-primary">
              <CardBody>
                <Row>
                  <Col md="3">
                    <Card className="card-profile">
                      <CardBody className="pt-0" style={{ minHeight: 190 }}>
                        <div className="text-center mt-4">
                          <h5 className="h3">
                            { `${insights.selectedCustomer ? insights.selectedCustomer.data.first_name : ''} ${insights.selectedCustomer ? insights.selectedCustomer.data.last_name : ''}` }
                            <span className="font-weight-light">{ (insights.selectedCustomer && insights.selectedCustomer.data.personal && insights.selectedCustomer.data.personal.age) ? `, ${insights.selectedCustomer.data.personal.age}` : ''}</span>
                          </h5>
                          <div className="h5 font-weight-300">
                            { `${(insights.selectedCustomer && insights.selectedCustomer.data.location) ?  `${insights.selectedCustomer.data.location.city}, ${insights.selectedCustomer.data.location.state}` : 'United States'}` }
                          </div>
                          { (insights.selectedCustomer && insights.selectedCustomer.data.professional) &&
                            <div className="h5 mt-4">
                              { insights.selectedCustomer.data.professional.job_title  } - { insights.selectedCustomer.data.professional.industry }
                            </div>
                          }
                          { (insights.selectedCustomer && insights.selectedCustomer.data.personal && insights.selectedCustomer.data.personal.education) &&
                            <div className="h5 mt-4">
                              { insights.selectedCustomer.data.personal.education }
                            </div>
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col xl="12">
                        <div className="customer-profile" style={{
                          padding: '10px 10px 0px 10px'
                        }}>
                          <h1 className="text-white mb-0" style={{ fontSize: 20}}>{ insights.selectedCustomer.data.persona ? insights.selectedCustomer.data.persona.name : '' }</h1>
                          <hr/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4">
                        <Card className={`card-stats text-white bg-white`}>
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className={`text-uppercase text-muted mb-0 text-primary`}
                                >
                                  Lifetime Value
                                </CardTitle>
                                <span className={`h2 font-weight-bold mb-0`}>{ insights.selectedCustomer.data.lifetime_value ? `$${insights.selectedCustomer.data.lifetime_value}` : '' }</span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card className={`card-stats text-white bg-white`}>
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className={`text-uppercase text-muted mb-0 text-primary`}
                                >
                                  Total Orders
                                </CardTitle>
                                <span className={`h2 font-weight-bold mb-0`}>{ insights.selectedCustomer.data.total_orders ? insights.selectedCustomer.data.total_orders : '' }</span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card className={`card-stats text-white bg-white`}>
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className={`text-uppercase text-muted mb-0 text-primary`}
                                >
                                  Average Order Value
                                </CardTitle>
                                <span className={`h2 font-weight-bold mb-0`}>{ insights.selectedCustomer.data.average_order_value ? `$${insights.selectedCustomer.data.average_order_value}` : '' }</span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            { (insights.selectedCustomer && insights.selectedCustomer.data.personal) &&
              <Card>
                <CardBody>
                  <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                    <span>Personal</span>
                  </CardTitle>
                  { insights.selectedCustomer.data.personal.age ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Age
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.personal.age}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.personal.gender  ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Gender
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.personal.gender == 'F' ? 'Female' : 'Male'}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.personal.children ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Children
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.personal.children == 'Y' ? 'Yes' : 'No'}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.personal.married ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Married
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{(insights.selectedCustomer.data.personal.married) ? (insights.selectedCustomer.data.personal.married == 'Y' ? 'Yes' : 'No') : ''}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.personal.education ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Educaton
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{(insights.selectedCustomer.data.personal.education) ? insights.selectedCustomer.data.personal.education : ''}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.personal.interests ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Interests
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{(insights.selectedCustomer.data.personal.interests) ? insights.selectedCustomer.data.personal.interests : ''}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                </CardBody>
              </Card>
            }
            { (insights.selectedCustomer && insights.selectedCustomer.data.professional) &&
              <Card>
                <CardBody>
                  <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                    <span>Professional</span>
                  </CardTitle>
                  { insights.selectedCustomer.data.professional.job_title ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Job Title
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.professional.job_title}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.professional.industry  ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Industry
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.professional.industry}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                  { insights.selectedCustomer.data.professional.income ?
                    <Row>
                      <Col md="1"></Col>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Income
                      </Label>
                      <Col md="6">
                        <Row className="col-form-label">
                          <div>{insights.selectedCustomer.data.professional.income}</div>
                        </Row>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    :
                    null
                  }
                </CardBody>
              </Card>
            }
          </Col>
          <Col lg="8">
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Recent Customer Activity</h3>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  { insights.selectedCustomer.data.activity &&
                    insights.selectedCustomer.data.activity.history.map((visit, index) => (
                      <div className="timeline-block">
                        <Row>
                          <span className="timeline-step badge-secondary">
                            <i className="ni ni-tag" />
                          </span>
                          <Col xl="12">
                            <div className="timeline-content">
                              <small className="text-muted font-weight-bold">
                                { visit.datetime }
                              </small>
                              <h5 className="mt-3 mb-0">{ visit.event }</h5>
                              <p>{ visit.campaign_content ? visit.campaign_content : '' }</p>
                              <div className="mt-3">
                                { visit.campaign_source ?
                                  <Badge color="secondary" pill>
                                    { visit.campaign_source }
                                  </Badge>
                                  :
                                  null
                                }
                                { visit.campaign_medium ?
                                  <Badge color="secondary" pill>
                                    { visit.campaign_medium ? visit.campaign_medium  : '' }
                                  </Badge>
                                  :
                                  null
                                }
                                { visit.campaign_name ?
                                  <Badge color="secondary" pill>
                                    { visit.campaign_name ? visit.campaign_name : '' }
                                  </Badge>
                                  :
                                  null
                                }
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCustomer = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewCustomer);

export default ConnectedViewCustomer;

/*
<Card
  className="bg-gradient-primary border-0"
  style={{
    height: 140
  }}
>
  <CardBody>
    <Row>
      <Col md="12">
        <Input
          className="form-control"
          type="text"
          placeholder="Ask this customer a question..."
          style={{
            zIndex: 100000000,
            position: 'absolute',
            width: '90%',
            top: 5
          }}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // Set persona
              dispatch({
                type: SET_PERSONA,
                payload: {
                  persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                }
              });

              // Set initial query
              if(query.length > 0) {
                dispatch({
                  type: SET_QUERY,
                  payload: {
                    query: query
                  }
                });
              }

              // Go to agent
              history.push(`/admin/knowledge/agent`);
            }
          }}
        />
        <div
          className="icon icon-shape bg-white text-dark rounded-circle shadow"
          style={{
            zIndex: 200000000,
            position: 'absolute',
            right: '2%',
            top: -2,
            height: 80,
            width: 80,
            cursor: 'pointer'
          }}
          onClick={() => {
            // Set persona
            dispatch({
              type: SET_PERSONA,
              payload: {
                persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
              }
            });

            // Navigate to agent
            history.push(`/admin/knowledge/agent`);
          }}
        >
          <i className="ni ni-books" />
        </div>
      </Col>
      <Col
        md="12"
        style={{
          paddingTop: 65
        }}
      >
        <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
          purchase motivations
        </Button>
        <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
          competing priorties
        </Button>
        <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
          personal motivations
        </Button>
      </Col>
    </Row>
  </CardBody>
</Card>
*/
