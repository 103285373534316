/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Account config
import config from "config";

function ProfileHeader({ profile, account }) {
  // Get history
  const history = useHistory();

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: `url(${config.assets.img.brand.backgrounds.profile})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-1" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10" style={{
              minWidth: 400
            }}>
              <h1 className="display-2 text-white">Hello{(profile.initialized && profile.user.details.firstName) ? ` ${profile.user.details.firstName}` : ``}</h1>
              <p className="text-white mt-0 mb-5">

              </p>
              <Button
                className="btn-info bg-secondary"
                color="default"
                href=""
                onClick={(e) => {
                  // Send back to home
                  history.push(`/admin/customers/personas`);
                }}
              >
                Customer Activity
              </Button>
              { (account && account.initialized && account.active && account.active.access && account.active.access.campaigns) ?
                  <Button
                  className="btn-info"
                  color="default"
                  href=""
                  onClick={(e) => {
                    // Send back to home
                    history.push(`/admin/campaigns`);
                  }}
                >
                  Active Campaigns
                </Button>
                :
                <></>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// Connect to store
const ConnectedProfileHeader = connect(state => ({
  profile: state.profile,
  account: state.account
}))(ProfileHeader);

export default ConnectedProfileHeader;

/*

<p className="text-white mt-0 mb-5">
  Welcome to Axon, the Cognitive Intelligence platform. We are here to provide you with powerful insights about your customers and their behavior. With our cutting-edge technology, you can easily access valuable data that will help you better understand their needs and preferences.
</p>

*/
