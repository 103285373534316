/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React router
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import classnames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

// Authentication
import { auth, firestore } from "./../../firebase";

function AdminNavbar({ profile, account, theme, sidenavOpen, toggleSidenav }) {
  // Navigation
  const history = useHistory();

  // Auth signout
  const logout = async () => {
    // Logout from Firebase
    await auth.signOut();

    // Navigate to login
    history.push("/auth/login");
  }

  // Set the active client
  const setActiveClient = async (client) => {
    // Set current default on account
    await firestore.collection("profiles").doc(profile.user.uid).set({
      selected: {
        account: account.config.accountId,
        client: client.id
      }
    }, { merge: true });

     // Navigate to home
    history.push("/admin/home");
    window.location.reload();
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>


            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              { (account && account.initialized) ? 
                <UncontrolledDropdown nav className="pr-4" style={{
                  cursor: 'pointer'
                }}>
                  <DropdownToggle className="nav-link pr-0" color="white" tag="a">
                    <Media className="align-items-center">
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          { account.active ? account.active.name : "" }
                        </span>
                        <i className="ni ni-bold-down ml-2" />
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    { account.available.map((client, index) => (
                      <DropdownItem
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();

                          // Set active client
                          setActiveClient(client);
                        }}
                      >
                        <span>{client.name}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                :
                <></>
              }
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={(profile.initialized) ? profile.user.account.imageUrl : "https://storage.googleapis.com/avant-garde-cdn/axon/assets/img/user.png" }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {(profile.initialized) ? profile.user.name : "" }
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-single-02" />
                    <span>My Profile</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={logout}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

// Connect to store
const ConnectedAdminNavbar = connect(state => ({
  profile: state.profile,
  account: state.account
}))(AdminNavbar);

export default ConnectedAdminNavbar;
